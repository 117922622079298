import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, Box, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../images/tren.png'; // Ensure this path is correct
import { useTheme } from '@mui/material/styles';

function Navbar() {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={NavLink} to="/" onClick={handleMobileMenuClose} sx={navLinkStyle(isMobileView)}>
        Home
      </MenuItem>
      <MenuItem component={NavLink} to="/about" onClick={handleMobileMenuClose} sx={navLinkStyle(isMobileView)}>
        About Us
      </MenuItem>
      <MenuItem component={NavLink} to="/products" onClick={handleMobileMenuClose} sx={navLinkStyle(isMobileView)}>
        Products
      </MenuItem>
      <MenuItem component={NavLink} to="/contact" onClick={handleMobileMenuClose} sx={navLinkStyle(isMobileView)}>
        Contact Us
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{
        mt: 2,
        width: '100%',
        padding: '0 5%',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo and Brand Name with Link to Home Page */}
        <NavLink to="/" style={{ textDecoration: 'none' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img src={logo} alt="Logo" style={{ marginRight: 8, height: '40px' }} />
            <Typography variant="h6" color="inherit" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
              Prime Drive
            </Typography>
          </Box>
        </NavLink>

        {/* Desktop Menu */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3, alignItems: 'center' }}>
          <Button component={NavLink} to="/" sx={({ isActive }) => isActive ? activeNavLinkStyle(isMobileView) : navLinkStyle(isMobileView)}>
            Home
          </Button>
          <Button component={NavLink} to="/about" sx={({ isActive }) => isActive ? activeNavLinkStyle(isMobileView) : navLinkStyle(isMobileView)}>
            About Us
          </Button>
          <Button component={NavLink} to="/products" sx={({ isActive }) => isActive ? activeNavLinkStyle(isMobileView) : navLinkStyle(isMobileView)}>
            Products
          </Button>
          <Button component={NavLink} to="/contact" sx={({ isActive }) => isActive ? activeNavLinkStyle(isMobileView) : navLinkStyle(isMobileView)}>
            Contact Us
          </Button>
        </Box>

        {/* Let's Talk Button with Call Redirection */}
        <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
          <a href="tel:+94777201951" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: '30px',
                fontWeight: 'bold',
                padding: '6px 16px',
                backgroundColor: '#C8102E',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#A00C23',
                },
              }}
            >
              Let's Talk +
            </Button>
          </a>
        </Box>

        {/* Mobile Menu Icon */}
        <Box sx={{ display: { xs: 'flex', md: 'none' }, color: 'white' }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
}

// Default Navigation Link Styling, dynamic color based on isMobileView
const navLinkStyle = (isMobileView) => ({
  color: isMobileView ? '#000000' : '#ffffff',
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'none',
  '&:hover': {
    color: isMobileView ? '#C8102E' : '#C8102E',
  },
  padding: '0 10px',
  textDecoration: 'none',
});

// Active Navigation Link Styling, dynamic color based on isMobileView
const activeNavLinkStyle = (isMobileView) => ({
  ...navLinkStyle(isMobileView),
  color: '#C8102E',
  borderBottom: '2px solid #C8102E',
});

export default Navbar;
